<template>
  <div class="move-top">
    <div class="form-brief">
      <el-image :src="require('@/assets/img/homeFormImgs/submitProject.png')" fit="cover"></el-image>
      <p class="big-title"> 寻求报道 </p>
      <p class="content"> 如果你希望寻求报道，获得项目曝光，欢迎随时和我们联系。不管是你是已叱咤互金江湖的大公司，还是刚刚兴起的创业项目，你都可以来向我们寻求报道，我们涉及商业模式、高管洞见、融资项目、行业会议等。 提交资料，我们的编辑会尽快与您联系。资料包含公司的基本介绍，报道的需求，联系方式等。  </p>
      <p class="bottom"> *此服务为公益服务，若项目审核通过，会有工作人员与您取得联系。 </p>
    </div>
    <div class="form-container">
      <el-form ref="form" :label-position="isMobile ? 'top' : 'left'" label-width="80px" :model="form" :rules="rules">
        <el-form-item label="联系人:" label-width="8rem" prop="contactPerson">
          <el-input v-model="form.contactPerson" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机:" label-width="8rem" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入您的手机号码"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" label-width="8rem" prop="mailbox">
          <el-input v-model="form.mailbox" placeholder="请输入您的邮箱地址"></el-input>
        </el-form-item>
        <el-form-item label="公司名称:" label-width="8rem" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="项目名称:" label-width="8rem" prop="projectName">
          <el-input v-model="form.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="所属城市:" label-width="8rem" prop="city" class="address-formItem">
          <el-select v-model="province" placeholder="请选择地址" width="100%" :loading="provinceLoading">
            <el-option
              v-for="(item,index) in provinceList"
              :key="index"
              :label="item.label"
              :value="item.value"
            > </el-option>
          </el-select>
          <el-select v-model="city" placeholder="请选择地址" width="100%" :loading="cityLoading">
            <el-option v-for="(item,index) in cityList" :key="index" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select v-model="area" placeholder="请选择地址" width="100%" :loading="areaLoading">
            <el-option v-for="(item,index) in areaList" :key="index" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="行业领域:" label-width="8rem" prop="field">
          <el-select v-model="form.field" placeholder="请选择行业领域" width="100%">
            <el-option v-for="(item,index) in industryList" :key="index" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="产品服务:" label-width="8rem" prop="prproductService">
          <el-input v-model="form.prproductService" placeholder="请输入产品服务"></el-input>
        </el-form-item>

        <el-form-item label="融资状态:" label-width="8rem" prop="financingId">
          <el-select v-model="form.financingId" placeholder="请选择融资状态" width="100%">
            <el-option v-for="(item,index) in finacingStatusList" :key="index" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="项目简介:" label-width="8rem" prop="synopsis">
          <el-input v-model="form.synopsis" :autosize="{ minRows: 4}" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="上传附件:" label-width="8em">
          <el-upload
            class="upload-container"
            action="https://xinke.thinkervc.cn/api/venus/resource/upload"
            :before-upload="beforeUpload"
            :on-progress="handleProgress"
            :on-change="handleChange"
            :on-error="handleError"
            :on-success="handleSuccess"
            name="file"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="" label-width="8em">
          <div class="bottom-btn" @click="submitForm('form')"> 提交 </div>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { addEntrepreneurSeekReport, getEntrepreneurFinancingList } from '@/api'
export default {
  data() {
    // 检测手机号码正则
    let checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('电话不能为空'));
      }
      if (String(value).length !== 11) {
        callback(new Error('输入的号码长度有问题'));
      }
      var telReg = !!String(value).match(/^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/)
      if (!telReg) {
        callback(new Error('请输入真实电话号码'));
      }
      callback()
    };
    // 检测邮箱正则方法
    let checkEmail = (rule, value, callback) => {
      var reg = new RegExp('^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$')
      if (!reg.test(value)) {
        callback(new Error('请输入真实的邮箱'));
      }
      callback()
    }
    return {
      labelPosition: 'left',
      // form: {
      //   contactPerson: '',
      //   position: '',
      //   name: '',
      //   phone: '',
      //   mailbox: '',
      //   companyName: '',
      //   projectName: '',
      //   field: '',
      //   synopsis: '',
      //   fileList: ''
      // },
      form: {
        contactPerson: '',
        phone: '',
        mailbox: '',
        companyName: '',
        projectName: '',
        city: '',
        field: '',
        prproductService: '',
        financingId: '',
        synopsis: '',
        uploadAnnex: ''
      },
      fileList: [],
      tecentMapKey: 'V42BZ-OHRKG-KTUQN-II7YJ-MCEEF-I3BEG',
      tecentMapUrl: 'https://apis.map.qq.com/ws/district/v1/getchildren',
      province: '',
      city: '',
      area: '',
      provinceList: [],
      cityList: [],
      areaList: [],
      provinceLoading: false,
      cityLoading: false,
      areaLoading: false,
      rules: {
        contactPerson: [
          { required: true, message: '请输入联系人', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: '电话号码不能为空', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        mailbox: [
          { required: true, message: '请输入邮箱', trigger: ['blur', 'change'] },
          { validator: checkEmail, message: '请输入准确邮箱地址', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: ['blur', 'change'] }
        ],
        projectName: [
          { required: true, message: '请输入项目名称', trigger: ['blur', 'change'] }
        ],
        field: [
          { required: true, message: '请选择行业领域', trigger: ['blur', 'change'] }
        ],
        financingId: [
          { required: true, message: '请选择融资状态', trigger: ['blur', 'change'] }
        ],
        prproductService: [
          { required: true, message: '请输入产品服务', trigger: ['blur', 'change'] }
        ],
        synopsis: [
          { required: true, message: '请输入项目简介', trigger: ['blur', 'change'] }
        ]
      },
      industryList: [],
      finacingStatusList: [],
      // 该页面 loading 是否展示
      loading: null,
      uploadUrlList: [],

      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    }
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    }),
    filePathStr() {
      return JSON.stringify(this.uploadUrlList)
    }
  },
  watch: {
    province(v, oldv) {
      if (v === oldv) {
        return
      }
      v = JSON.parse(v)
      let value = v.value
      this.cityLoading = true
      this.getCityInfo(value).then(res => {
        this.cityLoading = false
        let firstRes = res
        this.cityList = firstRes
        this.city = this.cityList[0]['value']
      })
    },
    city(v, oldv) {
      if (v === oldv) {
        return
      }
      v = JSON.parse(v)
      let value = v.value
      this.areaLoading = true
      this.getCityInfo(value).then(res => {
        this.areaLoading = false
        let firstRes = res
        this.areaList = firstRes
        this.area = this.areaList[0]['value']
      })
    }
  },

  created() {
    this.initBaseInfo()
    this.initProvinceList()
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.city = this.infoToAddress()
          this.form['uploadAnnex'] = this.filePathStr
          this.loading = this.$loading({
            lock: true,
            text: '表单提交中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          // this.form['financingId'] = 1
          addEntrepreneurSeekReport(this.form).then(res => {
            if (res.code === 200) {
              this.$message({
                message: '提交成功',
                type: 'info',
                duration: 4000
              });
            }
            this.$refs['form'].resetFields();
            this.uploadUrlList = []
            this.fileList = []
            if (this.loading) {
              this.loading.close();
            }
          })
        }
      });
    },
    /**
     * @function initBaseInfo
     * @desc 初始化 本地数据
     */
    initBaseInfo() {
      getEntrepreneurFinancingList().then(res => {
        console.log(res);
        if (res.code === 200) {
          this.manageRequesBaseInfo(res.data)
        }
      })
    },
    /**
   * @function    manageRequesBaseInfo
   * @params      list [Array]
   * @description 接收一个数组,下拉框数据数组。对于不同的数据，赋值到本地 this 中的属性名
   */
    manageRequesBaseInfo(list) {
      list.some(item => {
        if (item.name === '融资状态') {
          this.finacingStatusList = item.children
        }
        if (item.name === '行业领域') {
          this.industryList = item.children
        }
      });
    },
    /**
     * @function infoToAddress
     * @desc 将地址的信息，整理为 自己想要的数据
     */
    infoToAddress() {
      let provinceArr = JSON.parse(this.province)
      let cityArr = JSON.parse(this.city)
      let areaArr = JSON.parse(this.area)
      let addressStr = provinceArr.name + cityArr.name + areaArr.name
      let addressCode = areaArr['value']
      let arr = { name: addressStr, value: addressCode }
      // let arr = [addressCode, addressStr]
      return JSON.stringify(arr)
    },
    /**
     * @function getCityInfo
     * @desc 根据城市 ID 返回城市信息
     */
    getCityInfo(cityId) {
      return new Promise((resolve, reject) => {
        let queryStr = this.tecentMapUrl + '?key=' + this.tecentMapKey

        if (cityId) {
          queryStr = queryStr + '&id=' + cityId
        }
        this.$jsonp(queryStr, { 'output': 'jsonp', 'content-type': 'application/xml' }).then(res => {
          let list = res.result[0]
          let newList = this.arrangCityInfo(list)
          resolve(newList)
        }).catch(() => {
          resolve([])
        })
      })
    },
    /**
     * @function arrangCityInfo
     * @desc 从腾讯地图获取到的数据 转化为  组件可以用的数据
     * @return [{label:'',value:''}]
     */
    arrangCityInfo(res) {
      let finalList = []
      if (res) {
        finalList = res.map(item => {
          let label = item.fullname || item.name
          let v = { 'value': item.id, 'name': label }
          let obj = { value: JSON.stringify(v), label }
          return obj
        })
      }
      return finalList
    },
    /**
     * @function initProvinceList
     * @desc 初始化 省 / 市 列表
     */
    initProvinceList() {
      this.provinceLoading = true
      this.getCityInfo().then(res => {
        this.provinceLoading = false
        let firstRes = res
        this.provinceList = firstRes
      })
    },
    /**
     * @function findFileName
     * @desc 根据行业领域 下拉框选择 的 value ，查找 name
     * @desc return name
    */
    findFileName(v) {
      let name = ''
      this.industryList.some(item => {
        if (item.value === v) {
          name = item.label
          return true
        }
      })
      return name
    },
    // 文件上传
    beforeUpload(file) {
      let fileMax = parseInt(file.size / 1024 / 1024)
      let isLt5M = fileMax > 80;
      if (isLt5M) {
        this.$message({
          message: '上传文件超出限制，该文件大小: ' + fileMax + 'M',
          type: 'warning'
        })
        return false
      }
    },
    handleProgress() {
      this.loading = this.$loading({
        lock: true,
        text: '文件上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.2)'
      });
    },
    // 取得文件后缀名
    getExtname(fileName) {
      let lastIndex = fileName.lastIndexOf('.') + 1
      return fileName.substring(lastIndex)
    },
    // 附件上传
    handleChange(file, fileList) {
      let obj = { name: file.name, url: '', extname: '' }
      let extname = this.getExtname(file.name)
      obj.extname = extname
      if (this.loading) {
        this.loading.close();
      }
      this.fileList = fileList
      if (file.response && file.response.code === 200) {
        obj.url = file.response.data
        let uploadList = this.uploadUrlList
        uploadList.push(obj)
        this.uploadUrlList = uploadList
      }
      if (file.response && file.response.code !== 200) {
        this.fileList.pop()
        let msg = file.response['message']
        this.$message({
          message: msg,
          type: 'warning'
        })
      }
    },
    handleSuccess(response) {
      console.log(response)
      // if (this.loading) {
      //   this.loading.close();
      // }
      // let uploadList = this.uploadUrlList
      // uploadList.push(response.data.url)
      // this.uploadUrlList = uploadList

      // if (response.code === 200) {
      //   let uploadList = this.uploadUrlList
      //   uploadList.push(response.data)
      //   this.uploadUrlList = uploadList
      // } else {
      //   const copyList = this.fileList
      //   this.fileList = copyList.splice(0, this.fileList.length - 1)
      //   this.$message({
      //     message: 'empty file',
      //     type: 'warning'
      //   })
      // }
    },
    handleError() {
      if (this.loading) {
        this.loading.close();
      }
      this.$message({
        message: '上传文件异常',
        type: 'warning'
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.move-top{
  margin: -1rem auto;
}
.form-brief{
  position: relative;
  width: 100%;
  height: 360px;
  padding: 2rem 0;
  color: #fff;

  .el-image{
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .big-title{
    font-size: 36px;
    padding: 3rem 0 1rem;
    font-weight: bold;
    text-align: center;
  }

  .content,.bottom{
    width: 1200px;
    padding: 1rem;
    margin: auto;
  }
  .bottom{
    font-weight: 700;
    color: #ffbb00;
  }
}

.form-container{
  width: 1200px;
  margin: auto;
  padding: 2rem;
  .el-form{
    .el-form-item{
      margin-top: 1.5rem;
      ::v-deep .el-form-item__label {
        width: 6rem;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
      }

      ::v-deep .el-select{
        width: 100%;
      }
    }
    .address-formItem{
      ::v-deep .el-select{
        width: 30%;
        margin-right: 3%;
      }
    }
  }
}
.bottom-btn{
  width: 80%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  background: linear-gradient(0deg,#f08519, #ffaa54);
  border-radius: 4px;
}
.upload-container{
  ::v-deep .el-upload-list__item {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .self-wrap-class{
    .form-brief{
      height: auto;
      .content,.bottom{
        width: 100%;
      }
    }
    .form-container{
      width: 100%;
      padding: .2rem;
      .el-form{
        .address-formItem{
          ::v-deep .el-select{
            width: 33%;
            margin: 0;
          }
        }

      }
    }
    .bottom-btn{
      width: 100%
    }
  }
}
</style>
